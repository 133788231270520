import { graphql } from 'gatsby';
import React from 'react';
import * as styles from '../pages/about-us/about-us.module.scss';
import Layout from '../components/layout/Layout';
import { AboutUsFrontmatter, IAboutUsData } from '../types/aboutUsTypes';
import classNames from 'classnames';
import TitleH2 from '../components/title-h2/TitleH2';
import BodyText from '../components/body-text/BodyText';
import { Fade, Slide, Zoom } from 'react-reveal';

type AboutUsTemplateProps = {
    html: string,
    frontmatter: AboutUsFrontmatter
}

export const AboutUsTemplate: React.FC<AboutUsTemplateProps> = ({ html, frontmatter }) => {
    const { title, subtitle, background, rhombusImage, subRhombusImage, subBackground, heading } = frontmatter;

    return (
        <main>
            <section className={styles.main} style={{ backgroundImage: `url(${background})` }}>
                <div className={classNames(classNames, styles.mainContainer, 'wrapper')}>
                    <Fade delay={500}>
                        <h1 className={styles.mainTitle}>{title}</h1>
                    </Fade>

                    <Fade delay={500}>
                        <p className={styles.mainSubtitle}>
                            {subtitle}
                        </p>
                    </Fade>

                    <img className={styles.mainImage} src={rhombusImage} alt="Main sectiom"  />
                </div>
            </section>

            <section className={styles.about} style={{ backgroundImage: `url(${subBackground})` }}>
                <div className={classNames(classNames, styles.aboutContainer, 'wrapper')}>
                    <Zoom big>
                        <img className={styles.aboutImage} src={subRhombusImage} alt="About image" />
                    </Zoom>

                    <Slide left>
                        <TitleH2 className={styles.aboutTitle}>{heading}</TitleH2>
                    </Slide>

                    <Slide left>
                        <BodyText html={html} />
                    </Slide>
                </div>
            </section>
        </main>
    );
};

type AboutUsContainerProps = {
    data: IAboutUsData
}

const AboutUsContainer: React.FC<AboutUsContainerProps> = ({ data }) => {
    const { frontmatter, html } = data.markdownRemark;

    return (
        <Layout>
            <AboutUsTemplate frontmatter={frontmatter} html={html} />
        </Layout>
    );
};

export const aboutUsPageQuery = graphql`
  query AboutUsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        background
        templateKey
        background
        rhombusImage
        subRhombusImage
        subBackground
        heading
      }
    }
  }
`;

export default AboutUsContainer;

